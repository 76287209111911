import {Box, CircularProgress} from "@mui/material";
import React from "react";
import Logo from "./Logo";

type OwnProps = {
    size: "small" | "medium" | "large";
};

type Props = OwnProps;

const Loader: React.FC<Props> = props => {
    const {size} = props;
    let cpSize;
    switch (size) {
        case "small":
            cpSize = "30px";
            break;
        case "medium":
            cpSize = "35px";
            break;
        case "large":
            cpSize = "45px";
            break;
        default:
            cpSize = "35px";
            break;
    }
    return (
        <Box sx={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
            <CircularProgress color="warning" size={cpSize}/>
            <div style={{position: "absolute"}}>
                <Logo/>
            </div>
        </Box>
    );
}

export default Loader;
