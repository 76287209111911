import './App.css';
import firebaseConfig from "./firebaseConfig.json";
import {createTheme, ThemeProvider} from '@mui/material';
import authMethods from "./authMethods.json";
import React from "react";
import AuthProvider from "./components/Auth";
import FireactProvider from "./components/Fireact";
import pathnames from "./navigation/pathnames.json";
import Router from "./navigation/Router";


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

type OwnProps = {};

type Props = OwnProps;


const App: React.FC<Props> = props => {
    const {} = props;
    const config = {
        firebaseConfig: firebaseConfig,
        brand: "Clever AI - Admin",
        pathnames: pathnames,
        authProviders: authMethods
    }

    return (
        <FireactProvider config={config}>
            <ThemeProvider theme={darkTheme}>
                <AuthProvider>
                    <Router/>
                </AuthProvider>
            </ThemeProvider>
        </FireactProvider>
    )
}

export default App;
