import logoImage from "../assets/images/logo512.png";
import React from "react";

type OwnProps = {};

type Props = OwnProps;

const Logo: React.FC<Props> = props => {
    const {} = props;
    return (
        <img src={logoImage} alt={"Clever AI Logo"} style={{width: 50, borderRadius: 10}}/>
    );
}

export default Logo;
