import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {Navigate, Outlet} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {FireactContext} from "./Fireact";
import {getFunctions} from "firebase/functions";
import Loader from "./Loader";
import {fetchAndActivate, getRemoteConfig} from 'firebase/remote-config';


export const AuthContext = React.createContext<{
    authUser: any,
    setAuthUser: any,
    firebaseApp: any,
    authInstance: any,
    firestoreInstance: any,
    functionsInstance: any,
    remoteConfig: any,
    brand?: string
    // @ts-ignore
}>({
    authUser: {},
    firebaseApp: {},
    authInstance: {},
    firestoreInstance: {},
    functionsInstance: {},
    remoteConfig: {},
    brand: ''
});

type OwnProps = {};

type Props = OwnProps & PropsWithChildren<{}>;

const Auth: React.FC<Props> = props => {
    const {children} = props;
    // authorized user state
    const [authUser, setAuthUser] = useState<any>(
        {
            user: null,
            data: {},
            checked: false
        }
    );

    const {config} = useContext(FireactContext);
    const [firebaseApp, setFirebaseApp] = useState<any>(null);
    const [authInstance, setAuthInstance] = useState<any>(null);
    const [firestoreInstance, setFirestoreInstance] = useState<any>(null);
    const [functionsInstance, setFunctionsInstance] = useState<any>(null);
    const [remoteConfig, setRemoteConfig] = useState<any>(null);

    useEffect(() => {
        const app = initializeApp(config.firebaseConfig);
        const auth = getAuth(app);
        const firestore = getFirestore(app);
        const functions = getFunctions(app);
        const rc = getRemoteConfig(app);
        rc.settings.minimumFetchIntervalMillis = 10000;

        setFirebaseApp(app);
        setAuthInstance(auth);
        setFirestoreInstance(firestore);
        setFunctionsInstance(functions);
        setRemoteConfig(rc);

        onAuthStateChanged(auth, (user) => {
            if (user !== null) {
                user.getIdToken().then(token => {
                    const userDoc = doc(firestore, 'users', user.uid);
                    setAuthUser((prevState: any) => ({
                        ...prevState,
                        user: user,
                        checked: true
                    }));
                    setDoc(userDoc, {
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        email: user.email
                    }, {merge: true});
                    fetchAndActivate(rc).then(s => {
                    }).catch(error => {
                    });
                });
            } else {
                setAuthUser((prevState: any) => ({
                    ...prevState,
                    user: null,
                    checked: true
                }));
            }
        });
    }, [config.firebaseConfig]);

    return (
        <AuthContext.Provider value={{
            authUser, setAuthUser, firebaseApp, authInstance, firestoreInstance, functionsInstance, remoteConfig
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const AuthRoutes = () => {
    const {authUser} = useContext(AuthContext);
    const {config} = useContext(FireactContext);
    const signInPath = config.pathnames.SignIn;

    if (authUser.checked) {
        if (authUser.user !== null) {
            return <Outlet/>
        } else {
            return <Navigate
                to={signInPath + "?re=" + document.location.pathname + document.location.search + document.location.hash}/>
        }
    } else {
        return (
            <Box mt={10}>
                <Container maxWidth="sm">
                    <Box component="span" m={5} textAlign="center">
                        <Loader size={"large"}/>
                    </Box>
                </Container>
            </Box>
        )
    }
}

export default Auth;
