import React, {useContext, useEffect} from "react"
import {AuthContext} from "./Auth"

type OwnProps = {
    title: string;
};

type Props = OwnProps;

const SetPageTitle: React.FC<Props> = props => {
    const {title} = props;
    const {brand} = useContext(AuthContext);
    useEffect(() => {
        document.title = brand ? (`${title} - ${brand}`) : (title);
    }, [title, brand]);
    return (<></>);
}

export default SetPageTitle;
