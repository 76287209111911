import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthRoutes} from "../components/Auth";
import {AppTemplate} from "../components/templates/AppTemplate";
import UserProfilePage from "../pages/user/UserProfilePage";
import pathnames from "./pathnames.json";
import UserUpdateEmailPage from "../pages/user/UserUpdateEmailPage";
import UserUpdateNamePage from "../pages/user/UserUpdateNamePage";
import UserUpdatePasswordPage from "../pages/user/UserUpdatePasswordPage";
import UserDeletePage from "../pages/user/UserDeletePage";
import PublicTemplate from "../components/templates/PublicTemplate";
import SignInPage from "../pages/auth/SignInPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import ActionPages from "../pages/auth/ActionPages";
import React from "react";

type OwnProps = {};

type Props = OwnProps;

const Router: React.FC<Props> = props => {
    const {} = props;
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AuthRoutes/>}>
                    <Route element={<AppTemplate/>}>
                        <Route path="/" element={<UserProfilePage/>}/>
                        <Route path={pathnames.UserProfile} element={<UserProfilePage/>}/>
                        <Route path={pathnames.UserUpdateEmail} element={<UserUpdateEmailPage/>}/>
                        <Route path={pathnames.UserUpdateName} element={<UserUpdateNamePage/>}/>
                        <Route path={pathnames.UserUpdatePassword} element={<UserUpdatePasswordPage/>}/>
                        <Route path={pathnames.UserDelete} element={<UserDeletePage/>}/>
                    </Route>
                </Route>
                <Route element={<PublicTemplate/>}>
                    <Route path={pathnames.SignIn} element={
                        <SignInPage/>
                    }/>
                    <Route path={pathnames.ResetPassword} element={
                        <ResetPasswordPage/>
                    }/>
                    <Route path={pathnames.ActionPages} element={
                        <ActionPages/>
                    }/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
