import {Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {FireactContext} from "../../components/Fireact";

type OwnProps = {
    customItems?: React.ReactNode;
};

type Props = OwnProps;

const MainMenu: React.FC<Props> = props => {
    const {customItems} = props;
    const {config} = useContext(FireactContext);
    const pathnames = config.pathnames;
    const profileUrl = pathnames.UserProfile;
    return (
        <List component="nav">
            <NavLink to="/" style={{textDecoration: 'none'}} key="home">
                <ListItemButton>
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText primary={<Typography color="textPrimary">Home</Typography>}/>
                </ListItemButton>
            </NavLink>
            {customItems}
            {profileUrl && [
                <Divider key="profile-divider"/>,
                <NavLink to={profileUrl} style={{textDecoration: 'none'}} key="profile">
                    <ListItemButton>
                        <ListItemIcon><AccountBoxIcon/></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">My Account</Typography>}/>
                    </ListItemButton>
                </NavLink>
            ]}
        </List>
    )
}

export default MainMenu;


