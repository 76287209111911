import {Alert, Box, Button, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import SetPageTitle from "../../components/SetPageTitle";
import {getAuth, updateProfile} from "firebase/auth";
import {FireactContext} from "../../components/Fireact";


type OwnProps = {};

type Props = OwnProps;

const UserUpdateNamePage: React.FC<Props> = props => {
    const {} = props;
    const [fullname, setFullname] = useState("");
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState(false);
    const [processing, setProcessing] = useState(false);
    const title = "Change Name";
    const navigate = useNavigate();

    const auth = getAuth();

    const {config} = useContext(FireactContext);
    const pathnames = config.pathnames;

    return (
        <Container maxWidth="md">
            <SetPageTitle title={title}/>
            <Paper>
                <Box p={2}>
                    <Typography component="h1" variant="h4" align="center">{title}</Typography>
                </Box>
                {error !== undefined &&
                    <Box p={2}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                }
                {success &&
                    <Box p={2}>
                        <Alert severity="success">Your name has been updated successfully.</Alert>
                    </Box>
                }
                <Box p={2}>
                    <TextField required fullWidth name="fullname" label="Full Name" autoComplete="name" type="text"
                               margin="normal" onChange={e => setFullname(e.target.value)}/>
                </Box>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs>
                            <Button type="button" color="secondary" variant="outlined" disabled={processing}
                                    onClick={() => {
                                        navigate(pathnames.UserProfile);
                                    }}>Back</Button>
                        </Grid>
                        <Grid item>
                            <Button type="button" variant="contained" disabled={processing} onClick={() => {
                                setProcessing(true);
                                setSuccess(false);
                                setError(undefined);
                                if (fullname.trim() === "") {
                                    setError('Your full name is required.');
                                    setProcessing(false);
                                } else if (auth.currentUser) {
                                    updateProfile(auth.currentUser, {displayName: fullname}).then(() => {
                                        setSuccess(true);
                                        setProcessing(false);
                                    }).catch(error => {
                                        switch (error.code) {
                                            case "auth/requires-recent-login":
                                                setError("This operation is sensitive and requires recent authentication. Log in again before retrying this request.");
                                                break;
                                            default:
                                                setError(error.message);
                                                break;
                                        }
                                        setProcessing(false);
                                    });
                                }
                            }}>Save</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    )
}


export default UserUpdateNamePage;
