import React, {PropsWithChildren} from "react";

export const FireactContext = React.createContext<{ config: any }>({config: {}});

type OwnProps = {
    config: any;
};

type Props = OwnProps & PropsWithChildren<{}>

const Fireact: React.FC<Props> = props => {
    const {config, children} = props;
    return (
        <FireactContext.Provider value={{config}}>
            {children}
        </FireactContext.Provider>
    )
}

export default Fireact;

